<template>
  <footer class="p-12 bg-black text-custom-orange">
    <div class="grid grid-cols-[auto,_1fr] items-center justify-items-start gap-x-4 gap-y-2 w-fit mx-auto">
      <div class="bg-custom-orange w-4 h-full rounded-lg"></div>
      <p class="text-2xl">Mamema Tree Kft.</p>
      <font-awesome-icon icon="fa-solid fa-phone" class="justify-self-center" />
      <a href="tel:+36302020624" class="underline md:hover:text-white md:transition-colors">+36302020624</a>
      <font-awesome-icon icon="fa-solid fa-envelope" class="justify-self-center" />
      <a href="mailto:favagaskomarom@gmail.com" class="underline md:hover:text-white md:transition-colors">
        favagaskomarom@gmail.com</a>
      <font-awesome-icon icon="fa-brands fa-facebook" class="justify-self-center" />
      <a href="https://www.facebook.com/favagaskomarom" target="_blank"
        class="underline md:hover:text-white md:transition-colors">
        facebook.com/favagaskomarom
      </a>
      <font-awesome-icon icon="fa-brands fa-facebook" class="justify-self-center" />
      <a href="https://www.facebook.com/tetomosaskomarom" target="_blank"
        class="underline md:hover:text-white md:transition-colors">
        facebook.com/tetomosaskomarom
      </a>
      <font-awesome-icon icon="fa-brands fa-instagram" class="justify-self-center" />
      <a href="https://www.instagram.com/favagaskomarom" target="_blank"
        class="underline md:hover:text-white md:transition-colors">
        instagram.com/favagaskomarom
      </a>
    </div>
    <div class="text-center md:text-left md:grid md:grid-cols-[auto,_auto,_auto] md:justify-center md:gap-4 mt-12">
      <p>
        Készítette:
        <a href="https://duplaem.hu" target="_blank" class="underline md:hover:text-white md:transition-colors">
          duplaem.hu
        </a>
      </p>
      <p class="hidden md:block">|</p>
      <p>
        Copyright <font-awesome-icon icon="fa-solid fa-copyright" />
        2023 Mamema Tree Kft. Minden jog fenntartva!
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {},
  methods: {},
  computed: {},
};
</script>